import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BigHeaderImage from "../../../components/header/bigHeader/bigHeaderImage"
import "../../../components/layout/layout.css"
import "../../../components/devicon/devicon.css"
import logo from "../../../../static/logos/medl.png"
import Kontakt from "../../../components/kontakt"
import Navigation from '../../../components/projekte/navigation'
import {AnchorLink} from "gatsby-plugin-anchor-links"
import ThumbleisteSwiper from "../../../components/thumbleisteSwiper"
import Liste from "../../../components/liste"
import ListenModule from "../../../components/listenModule"
import Specs from "../../../components/specs"
import BigHeaderVideo from "../../../components/header/bigHeader/bigHeaderVideo"
import * as projekteStyles from "../projekte.module.css"
import myLocalVid from '../../../images/projekte/medl-website/medl-herovid.mp4'


const SmartHomeKonfigurator = function (props, ref) {

    const data = useStaticQuery(graphql`
        query MedlWebsiteQuery {
            heropic: file(relativePath: { eq: "images/projekte/medl-website/medl-website-hero.jpg" }) {
                childImageSharp {
                    gatsbyImageData(          
                      placeholder: BLURRED,
                      layout: FULL_WIDTH,
                      aspectRatio: 1.7
                    )
                  }
            }

            ogimage: file(relativePath: { eq: "images/projekte/medl-website/medl-website-hero.jpg" }) {
                childImageSharp {
                    fixed(height: 400) {
                        src
                        height
                        width
                    }
                }
            }

            allFile(filter: {extension: {regex: "/(jpg|png)/"}, relativeDirectory: {eq: "images/projekte/medl-website/thumbleiste"}} sort: {fields: name}) {
                edges {
                  node {
                    id
                    childImageSharp {
                        picscropped:gatsbyImageData(placeholder: DOMINANT_COLOR, aspectRatio: 1, layout: FULL_WIDTH, formats: [WEBP], transformOptions: {rotate: 0, cropFocus: CENTER})
                        pics:gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH, formats: [WEBP])
                      }
                  }
                }
              }                                     
        }
    `)

    const specsData = [
        { title: "Eckdaten", objects: [logo, "Webseite", "https://medl.de"] },
        { title: "Leistungen", objects: ["konzept", "design", "fotoshooting", "programmierung"] },
        {
          title: "Toolchain",
          objects: ["sketch", "html", "css", "js", "wordpress"],
        },
      ]
  
    return (
        <Layout>
            <SEO
                title="medl Website"
                description="Q:marketing realisiert Website-Relaunch für Mülheimer Energiedienstleister."
                image={data?.ogimage?.childImageSharp?.fixed}
            />

            <BigHeaderVideo mode="textFirst" className={`${projekteStyles.child}`} bgVideo={myLocalVid} videoMode="local" posterImage="">                
                <div className="row py-5 py-md-0">
                    <div className="col-12 col-md-5">
                        <img src={logo} className="h-40px" alt=""/>
                        <h1>medl.de</h1>
                        <p className="introtext whitetextshadow">Website-Relaunch für den Mülheimer Energiedienstleister medl.</p>
                        <AnchorLink to="/projekte/websites/medl/#thumbleiste" title="↓ Mehr Erfahren" className="q-btn q-btn-primary" />
                    </div>
                    <div className="col-7"></div>
                </div>
            </BigHeaderVideo>

            {/* Bilderstrecke Thumbs */}   
            <ThumbleisteSwiper picdata={data.allFile} bgCol="#EFEFE6"/>    

            {/* Reasons why */}
            <ListenModule headline="Reasons why">
                <Liste
                    items={[
                        "Berechnung von individuell angepassten Stromtarifen.",
                        "Konzeptionelle überarbeitung der Informationsarchitektur.",
                        "Informationsbeschaffung, Kontaktaufnahme, Meldung von Störungen für Kunden.",
                        "Redaktionssystem zur sebstständigen Bearbeitung durch den Kunden.",
                        "Designrelaunch durch Entwicklung eines neuen Screendesigns.",
                        "Geräteübergreifende Nutzung für Smartphones, Tablets und Desktop-Rechner.",
                        "Fotoshooting für authentische Bildsprache."
                    ]}
                />
            </ListenModule>
            
            <Specs data={specsData} />
            
            {/* navigation */}            
            <Navigation location={props.location} />

            {/* kontakt */}            
            <Kontakt ansprechpartner="christian-frieling"/>

        </Layout>
    )
}

export default SmartHomeKonfigurator